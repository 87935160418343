<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="multiLineText"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
        ></cb-question-and-subtext>
    </div>

    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled"  [expanded]="true">
    
        <div class="form-fields-group">
            <mat-checkbox class="cb-form-field" formControlName="allow_html"
                >{{
                    'SURVEY-EDITOR.ITEMS.MULTI-LINES.ENABLE-HTML'
                        | translate
                }}
            </mat-checkbox>
            <mat-form-field class="cb-form-field">
                <input
                    matInput
                    autocomplete="off"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.MULTI-LINES.MIN' | translate
                    }}"
                    type="number"
                    (keypress)="onNumericKeyUp($event)"
                    min="0"
                    formControlName="min_length"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <input
                    matInput
                    autocomplete="off"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.MULTI-LINES.MAX' | translate
                    }}"
                    type="number"
                    (keypress)="onNumericKeyUp($event)"
                    min="0"
                    formControlName="max_length"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <input
                    matInput
                    autocomplete="off"
                    placeholder="{{
                        'SURVEY-EDITOR.ITEMS.MULTI-LINES.DEFAULT'
                            | translate
                    }}"
                    formControlName="default_text"
                />
            </mat-form-field>
        </div>
        <div class="form-fields-group" [removeIfFeatureOff]="'enabled_sentiment_analysis'">
            <div class="form-fields-container-outer">
                <div class="form-fields-container-inner">
                    <mat-checkbox
                    class="cb-form-field"
                    formControlName="enable_sentiment_analysis"
                    >
                        {{ 'SURVEY-EDITOR.SHARED.ENABLE-SENTIMENT-ANALYSIS' | translate }}
                    </mat-checkbox>
                </div>
                <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.SENTIMENT-ANALYSIS' | translate"></cb-help-icon>
            </div>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        type="number"
                        (keypress)="onNumericKeyUp($event)"
                        autocomplete="off"
                        formControlName="rows"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.ROWS' | translate
                        }}"
                    />
                </mat-form-field>
                <mat-form-field class="cb-form-field">
                    <input
                        matInput
                        type="number"
                        (keypress)="onNumericKeyUp($event)"
                        autocomplete="off"
                        formControlName="cols"
                        placeholder="{{
                            'SURVEY-EDITOR.SHARED.APPEARANCE.COLUMNS'
                                | translate
                        }}"
                    />
                </mat-form-field>
            </div>
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
            </div>
        </cb-question-collapsed-panel>
    </div>

    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
    <cb-wcag-alert *ngIf="allow_html"></cb-wcag-alert>
</form>
